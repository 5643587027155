import * as React from "react"
import { Link, graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { kebabCase } from "lodash"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NoteTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const coverImage = getImage(post.frontmatter.cover)

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <main className="container max-w-4xl px-4 flex-grow">
        <article itemScope itemType="http://schema.org/Article">
          <header className="mb-4">
            {/* title */}
            <h1
              itemProp="headline"
              className="font-bold text-3xl lg:text-4xl pb-1"
            >
              {post.frontmatter.title}
            </h1>

            {/* description */}
            {post.frontmatter.description && (
              <p className="text-xl lg:text-2xl pb-1">
                {post.frontmatter.description}
              </p>
            )}

            {/* date stuff */}
            <time dateTime={post.frontmatter.created}>
              <p className="text-base pt-1">
                {post.frontmatter.date_created}
                {post.frontmatter.created !== post.frontmatter.updated &&
                  ` (Updated: ${post.frontmatter.date_updated})`}
              </p>
            </time>
          </header>

          {/* cover image */}
          {post.frontmatter.cover && <GatsbyImage image={coverImage} alt="" />}

          {/* content section */}
          <section
            className="content mt-8"
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </article>
      </main>
      <aside className="container max-w-4xl px-4 lg:px-0">
        {/* content tags */}
        <nav>
          {post.frontmatter.tags && (
            <ul className="flex flex-wrap justify-center">
              {post.frontmatter.tags.map(tag => (
                <li>
                  <Link
                    to={`/tags/${kebabCase(tag)}/`}
                    className="tag--button inline-block border-2 rounded-full px-3 py-1 mx-1 text-sm"
                    key={tag}
                  >
                    {tag}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </nav>
      </aside>
    </Layout>
  )
}

export default NoteTemplate

export const pageQuery = graphql`
  query NoteBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }

    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        date_created: created(formatString: "LL")
        date_updated: updated(formatString: "LL")
        created
        updated
        tags
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 960
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }

  }
`
